@tailwind base;
@tailwind components;
@tailwind utilities;

.button_mint, .column_mint {
    cursor: pointer;
}

body {
    overflow: visible !important; /* Hide scrollbars */
    margin-bottom: 50px;
}

::-webkit-scrollbar {
    display: none;
}

/* Track */
::-webkit-scrollbar-track {
    display: none;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    display: none;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    display: none;
}

#WEB3_CONNECT_MODAL_ID {
    font-family: Russo One, sans-serif;
}

:root {
    --toastify-color-light: rgb(6, 22, 48);
    --toastify-icon-color-success: green;
    --toastify-icon-color-error: red;
    --toastify-text-color-light: white;
    --toastify-font-family: Russo One, sans-serif;
    --toastify-font-size: 6px;
}

.Toastify {
    font-size: 15px;
}

@media(max-width: 920px){
    .box_dash {
        width: 700px;
    }

    .box_dash4, .box_dash6, .box_dash7, .box_dash8 {
        width: 300px;
        font-size: 11px;
    }

    .headbox {
        width: 550px;
    }

    .head_active, .head_inactive {
        width: 100px;
    }
}

@media(max-width: 720px){
    .box_dash {
      width: 600px;
      font-size: 12px;
    }

    .box_dash4, .box_dash6, .box_dash7, .box_dash8 {
        width: 250px;
        font-size: 9px;
    }

    .headbox {
        width: 480px;
    }
  
    .head_active, .head_inactive {
        width: 89px;
        font-size: 8px;
    }
}

@media(max-width: 620px){
    .box_dash {
      width: 500px;
      font-size: 12px;
    }

    .box_dash4, .box_dash6, .box_dash7, .box_dash8 {
        width: 200px;
        font-size: 8px;
    }

    .headbox {
        width: 450px;
    }
  
    .head_active, .head_inactive {
        width: 75px;
        font-size: 7px;
    }
}